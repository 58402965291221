<template>
  <footer>
    <div class="container flex">
      <div class="footer__logo">
        <div class="logo">
          <router-link
            to="/"
            class="flex logo__link"
            :title="$store.state.title"
          >
            <img
              :src="logo"
              :alt="$store.state.title"
              class="logo__img"
              width="88"
              height="83"
            />
            <span class="flex logo__text">
              <span>{{ $store.state.title }} </span>
              <span>{{ $store.state.district }}</span>
            </span>
          </router-link>
        </div>
        <p>
          European Union काे सहायतामा सञ्चालित सुशासनमा नागरिक सहभागिता
          कार्यक्रम अन्तरगत {{ $store.state.title }}काे अभियान |
        </p>
      </div>
      <div class="flex footer__menu">
        <div>
          <h4 class="mb-16">यसभित्र</h4>
          <ul>
            <li v-if="localGov == 'khandachakra'">
              <a href="/sectors/health" title="स्वास्थ्य सम्बन्धित"
                >स्वास्थ्य सम्बन्धित</a
              >
            </li>
            <li v-if="localGov == 'bheri'">
              <a href="/sectors/education" title="शिक्षा सम्बन्धित"
                >शिक्षा सम्बन्धित</a
              >
            </li>
            <li v-if="localGov == 'kapurkot'">
              <a href="/sectors/agriculture" title="कृषि सम्बन्धित"
                >कृषि सम्बन्धित</a
              >
            </li>
            <li v-if="localGov == 'triveni'">
              <a
                href="/sectors/water_sanitation"
                title="पानी र सरसफाइ सम्बन्धित"
                >पानी र सरसफाइ सम्बन्धित</a
              >
            </li>
            <li v-if="localGov == 'siddhakumakh'">
              <a
                href="/sectors/water_sanitation"
                title="पानी र सरसफाइ सम्बन्धित"
                >पानी र सरसफाइ सम्बन्धित</a
              >
            </li>
            <li v-if="localGov == 'shibalaya'">
              <a href="/sectors/agriculture" title="कृषि सम्बन्धित"
                >कृषि सम्बन्धित</a
              >
            </li>
            <li>
              <a href="/citizen-charter" title="नागरिक वडापत्र"
                >नागरिक वडापत्र</a
              >
            </li>
            <li>
              <a href="/e-profile" title="वस्तुगत विवरण">वस्तुगत विवरण</a>
            </li>
            <li>
              <a href="/document-library" title="कागजात संग्रह"
                >कागजात संग्रह</a
              >
            </li>
          </ul>
        </div>
        <div>
          <h4 class="mb-16">उपयोगी लिङ्गकहरू</h4>
          <ul>
            <li><a href="/policies" title="नीतिहरु">नीतिहरु</a></li>
            <li><a href="/decisions" title="निर्णयहरु">निर्णयहरु</a></li>
            <li>
              <a href="/budget" title="कार्यक्षेत्रगत बजेट"
                >कार्यक्षेत्रगत बजेट</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import logo from "./../../assets/images/nepal-gov.svg";
export default {
  name: "AppFooter",
  data: function () {
    return {
      logo: logo,
      localGov: process.env.VUE_APP_LOCAL_GOV,
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  @include bg-primary-gradient;
  color: $neutrals-white;
  padding: 110px 20px;
  @media all and (max-width: 768px) {
    padding: 40px 20px;
  }
  .container {
    max-width: 1140px;
    @media all and (max-width: 768px) {
      display: block;
    }
  }
}
.footer__logo {
  max-width: 380px;
  p {
    font-family: "Mukta-Medium";
  }
  @media all and (max-width: 768px) {
    max-width: 100%;
  }
}
.logo {
  margin-bottom: 30px;
  &__link {
    align-items: center;
    color: $neutrals-white;
  }
  &__img {
    margin-right: 6px;
  }
  &__text {
    flex-direction: column;
    font-size: 14px;
    font-family: "Mukta-Bold";
    span {
      color: rgba($neutrals-white, 0.9);
      &:first-child {
        color: $neutrals-white;
        font-size: 20px;
      }
    }
  }
}
.footer__menu {
  justify-content: space-between;
  width: 45%;
  h4 {
    font-family: "Mukta-Bold";
  }
  @media all and (max-width: 768px) {
    display: block;
    margin-top: 40px;
    width: 100%;
    ul {
      margin-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      li {
        margin-right: 30px;
      }
    }
  }
  > div {
    margin-left: 40px;
    @media all and (max-width: 768px) {
      margin: 0;
    }
  }
  ul li {
    padding-bottom: 16px;
    a {
      color: $neutrals-white;
      font-family: "Mukta-Medium";
      &:hover {
        color: $neutrals-black;
      }
    }
  }
}
</style>
